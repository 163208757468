import React  from 'react';

const GIT_SHA = "7f4198a2";

interface Props {
  prefix?: string,
}

function GitSha(props: Props) {
  return (
    <span className="git-sha">{props.prefix}{GIT_SHA}</span>
  )
}

export { GitSha };
